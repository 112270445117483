import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../CSS/ReservasBookings.css';
import '../CSS/DateTimePicker.css';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pt';
import "react-datetime/css/react-datetime.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCheck } from '@fortawesome/free-solid-svg-icons';

const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|android|iphone|ipad|phone/i.test(userAgent);
}

const Reservas = () => {

    const [desafio, setDesafio] = useState('1');
    const location = useLocation();
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateDateType, setSelectedDateType] = useState(null);
    const [selectedHour, setSelectedHour] = useState('');
    moment.locale('pt'); //locale pt in the calendar

    const [submitted, setSubmitted] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    //number of participants ENUM EN
    const numPeoplePT = {
        '0': '- Select -',
        '1': '1 participant',
        '2': '2 participants',
        '3': '3 participants',
        '4': '4 participants',
        '5': '5 participants'
    };

    //prefered language EN
    const prefLanguagePT = {
        '0': '- Select -',
        'pt': 'Portuguese',
        'en': 'English',
        'fr': 'French'
    };

    //booking client data
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [nif, setNif] = useState('');
    const [numPeople, setNumPeople] = useState('0');
    const [langPref, setLangPref] = useState('0');
    const [result, setResult] = useState(0);

    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidName, setIsValidName] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);

    //List of available schedules for the defined range
    //Each element has a date and each date has a list of hours
    const [schedule, setSchedule] = useState({ Schedules: [] });

    //if we come from bookings, the challenge = 1. Else, challenge = selected
    useEffect(() => {

        const fetchSchedule = async () => {
            try {
                const response = await fetch('https://personal-d4yby5if.outsystemscloud.com/EscapeRoom_API/rest/Schedule/GetForWebsite_v2');
                if (!response.ok) {
                    throw new Error('Failed to fetch schedule');
                }
                const data = await response.json();
        
                setSchedule(data);
        
            } catch (error) {
                console.error('Error fetching schedule:', error);
            }
        };

        const selectedChallenge = localStorage.getItem('selectedChallenge');

        //fetchData();
        fetchSchedule();
 
        if (selectedChallenge!=='0') {
            setDesafio(selectedChallenge);
        } else {
            setDesafio('1'); //change this number to 0 when we have more than 1 challenge
        }

        setIsMobile(isMobileDevice());
    
    }, [location]);

    useEffect(() => {
        // Update localStorage whenever numPeople changes
        localStorage.setItem('numPeople', numPeople);
        validateForm();
      }, [numPeople]);
    
      useEffect(() => {
        // Update localStorage whenever langPref changes
        localStorage.setItem('langPref', langPref);
        validateForm();
      }, [langPref]);

    //set the challenge, to unlock the remaining form and finish the booking
    const changeChallengeOnClick = (challengeId) => {
        setDesafio(challengeId);
    }

    //limit the dates and range
    const isValidDate = (current) => {
        // Calculate the maximum allowed date (two months in advance)
        const maxDate = moment().add(2, 'months');
        // Calculate the minimum allowed date (tomorrow)
        const minDate = moment();
    
        // Check if the current date is between today and the max date
        if (!current.isSameOrBefore(maxDate, 'day') || current.isSameOrBefore(minDate, 'day')) {
            return false; // Date is outside the allowed range
        }
    
        // Check if the current date is blocked
        if (!schedule.Schedules.some(scheduleItem => moment(scheduleItem.Date).isSame(current, 'day')) || //day is not in the list
        schedule.Schedules.find(scheduleItem => moment(scheduleItem.Date).isSame(current, 'day')).Hours.length === 0) { //day has no hours left
            return false;
        }
    
        return true; // Date is valid
    };

    //load the hours of the selected day
    const handleDateChange = (chosenDate) => {
        setSelectedDateType(chosenDate);
        setSelectedHour('');

        //extract the date part from chosenDate, the time influences the moment
        const chosenDateOnly = moment(chosenDate).startOf('day');

        const dateString = chosenDateOnly.format('YYYY-MM-DD');
        setSelectedDate(dateString);
    };

    //select the hour and enable the rest of the form
    const handleButtonHoursClick = (hour) => {
        setSelectedHour(hour);
        //validateForm();
    }

    //handlers for the inputs of the form:
    //store the selected value when choosing the number of participants
    const handleNumPeopleChange = (event) => {
        setNumPeople(event.target.value);
        localStorage.setItem('numPeople', numPeople);
    };    

    //store the selected value when choosing the language
    const handlePrefLangChange = (event) => {
        setLangPref(event.target.value);
        localStorage.setItem('numPeople', numPeople);
    };
    
    const handleNameChange = (event) => {
        setIsValidName(event.target.value !== '')
        setName(event.target.value);
        validateForm();
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(inputEmail));
        setEmail(inputEmail);
        validateForm();
    };

    const handlePhoneChange = (event) => {
        setIsValidPhone(event.target.value !== '' && event.target.value.length>=9);
        setPhone(event.target.value);
        validateForm();
    };

    const handleTaxChange = (event) => {
        setNif(event.target.value);
        validateForm();
    };

    const calculateTotalAmount = () => {
    let total = 0;
    if (numPeople === '0') {
        total = 0;
    } else if (numPeople === '1') {
        total = 25;
    } else if (numPeople === '2') {
        total = 35;
    } else if (numPeople === '3') {
        total = 40;
    } else if (numPeople === '4') {
        total = 45;
    } else {
        total = 50;
    }
    setResult(total);
    };

    const validateForm = () => {
        if(isValidEmail && isValidName && isValidPhone && langPref !== '0' && numPeople !== '0' && selectedDate !== '0' && selectedHour !== '') {
            setIsValidForm(true);
            return true;
        } else {
            setIsValidForm(false);
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            const BookingRequest = {
                DataReserva: selectedDate,
                HoraReserva: selectedHour,
                DesafioId: desafio,
                NomeCliente: name,
                EmailCliente: email,
                Contacto: phone,
                Nif: nif,
                NumParticipantes: numPeople,
                LinguaPreferencial: langPref,
                Total: result,
                IsMobile: isMobile
            };
            try {
                const response = await axios.post('https://personal-d4yby5if.outsystemscloud.com/Booking_API/rest/Booking/Create_v2', BookingRequest);
                setSubmitted(true);
                setCompleted(true);
                setIsConfirmation(false);
            } catch (error) {
                setSubmitted(false);
                setCompleted(false);
            }
        }
    };

    const goBackButton = () => {
        setIsConfirmation(false);
        setSubmitted(false);
        setCompleted(false);
    };

    const validateButton = () => {
        //spining button to validade the form and continue the booking
        if(validateForm() === true) {
            calculateTotalAmount();
            setIsConfirmation(true);
            setSubmitted(false);
            setCompleted(false);
        }
    };

    return (
        <div className='bodyDiv'>                        
            <div>
                <p className='Title1'>Make your reservation here!</p>
                <p className='Title2'>Choose the challenge, date, and time that suits you best. We ask that you arrive a few minutes before the scheduled time. 
                When you make your reservation, you will receive a confirmation email, where you can review all the information.</p>
                <br />
                <p className='miniLetters'>Participants under the age of 16 can only participate with the presence of an adult, who will be responsible. 
                Participants with health conditions that prevent or limit any type of activity should inform the staff present. 
                If you have a voucher, bring it with you and present it when you arrive at the escape room. 
                If you have doubts or if your group does not meet the requirements, contact us.</p>
            </div>
            
            {/*completed = data filled; submitted = form submitted; isConfirmation = data validation*/}
            {submitted && completed && !isConfirmation ? (
            <div className='endCard'>
                <p className='Title1'>THANK YOU!</p>

                <p className='Title2'>Your reservation request has been successfully completed.</p>
                <p>You will soon receive a confirmation email at the submitted address.</p>
                <p>See you soon!</p>
            </div>) : (

            isConfirmation ? (
                <div className='confirmationCard'>
                    <div className='formDivConfirmation'>
                        <div className='formDivConfirmation-left'>
                            <p>Confirm your information</p>
                        </div>
                        <div className='formDivConfirmation-right'>
                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>Date:</p>
                                {selectedDate === '' ? (<p className='confirmationCardText'>-</p>) : (<p className='confirmationCardText'>{selectedDate} - {selectedHour}</p>)}
                            </div>

                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>Name:</p>
                                {name === '' ? (<p className='confirmationCardText'>-</p>) : (<p className='confirmationCardText'>{name}</p>)}
                            </div>

                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>Email:</p>
                                {email === '' ? (<p className='confirmationCardText'>-</p>) : (<p className='confirmationCardText'>{email}</p>)}
                            </div>

                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>Contact:</p>
                                {phone === '' ? (<p className='confirmationCardText'>-</p>) : (<p className='confirmationCardText'>{phone}</p>)}
                            </div>

                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>Tax Number:</p>
                                {nif === '' ? (<p className='confirmationCardText'>-</p>) : (<p className='confirmationCardText'>{nif}</p>)}
                            </div>

                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>Number of participants:</p>
                                {numPeople === '0' ? (<p className='confirmationCardText'>-</p>) : (<p className='confirmationCardText'>{numPeople}</p>)}
                            </div>

                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>Prefered language:</p>
                                {langPref === '0' ? (<p className='confirmationCardText'>-</p>) : (<p className='confirmationCardText'>{langPref}</p>)}
                            </div>

                            <div className='buttonDivLine'>
                                <p className='confirmationCardTitle'>The total amount is:</p>
                                <p className='confirmationCardText'>{result} €</p>
                            </div>

                            <form onSubmit={handleSubmit}>
                                    <div className='buttonDivLine-confirmation'>
                                        <button className='backButton' onClick={goBackButton}><FontAwesomeIcon icon={faAngleLeft}/> BACK</button>
                                        <button className='goButton' type="submit">BOOK <FontAwesomeIcon icon={faCheck} className="hiddenicon"/></button>
                                    </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (

            <div>
                {/*1st step: choose the challenge*/}
                {/*if no challenge is selected, the next steps are hidden.*/}
                <div className='formDiv1'>
                    <div className='formDiv1-left'>
                        <p>Challenge</p>
                    </div>
                    <div className='formDiv1-right'>
                        <div>
                            <button className={`formCardChallenges ${desafio === '1' ? 'formCardChallenges-selected' : ''}`} onClick={() => changeChallengeOnClick('1')}>The Theft of the Mask</button>
                        </div>
                    </div>
                </div>

                {/*2nd step: date and time*/}
                {/*if no date is selected, the available times are hidden.*/}
                <div className='formDiv2'>
                    <div className='formDiv2-left'>
                        <p>Date and Time</p>
                    </div>

                    {/*hide the section while no challenge is selected*/}
                    <div className='formDiv2-right'>
                        {/*Date selection:*/}
                        <div className='formDiv2-right1'>
                            <Datetime input={false} timeFormat={false} value={selectedDateDateType} onChange={handleDateChange} isValidDate={isValidDate}
                            locale="en"/>
                        </div>

                        {/* Time selection: */}
                        <div className='formDiv2-right2'>
                        {selectedDate && (
                            <div>
                                {schedule.Schedules.map(scheduleItem => {
                                    if (moment(scheduleItem.Date).isSame(selectedDate, 'day')) {
                                        return (
                                            <div key={scheduleItem.Date}>
                                                {scheduleItem.Hours.map((hour, index) => (
                                                    <button className={selectedHour === hour ? 'cardHour-selected' : 'cardHour'} key={`${scheduleItem.Date}-${index}`} onClick={() => handleButtonHoursClick(hour)}>
                                                        {hour}
                                                    </button>
                                                ))}
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        )}
                        </div>                        
                    </div>
                </div>

                {/*3rd step: personal info*/}
                {/*hide the section while no date time is selected*/}
                <form onSubmit={validateButton}>
                    <div className='formDiv3'>
                        <div className='formDiv3-left'>
                            <p>Client Information</p>
                            <p className='miniLetters'>(the provided data is necessary only for further contact, in case of unforeseen events)</p>
                            <p className="error">* Required field</p>
                        </div>

                        {selectedDate !== null && selectedHour !== '' ? (
                        <div className='formDiv3-right'>
                                {/*name*/}
                                <div className='formElementDiv'>
                                    {!isValidName && <label className="error">*</label>}
                                    <label htmlFor="name" className='Title3'>Name:</label>
                                    <input
                                        className='inputBars'
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={handleNameChange}
                                        required
                                    />
                                </div>

                                {/*email*/}
                                <div className='formElementDiv'>
                                    {!isValidEmail && <label className="error">*</label>}
                                    <label htmlFor="email" className='Title3'>Email:</label>
                                    <input
                                        className='inputBars'
                                        type="text"
                                        id="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                </div>

                                {/*phone + tax*/}
                                <div className='formElementDivGrouped'>
                                    {/*phone*/}
                                    <div>
                                        {!isValidPhone && <label className="error">*</label>}
                                        <label htmlFor="phone" className='Title3'>Contact:</label>
                                        <input
                                            className='inputBarsSmall'
                                            type="text"
                                            id="phone"
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            required
                                        />
                                    </div>

                                    {/*tax*/}
                                    <div>
                                        <label htmlFor="nif" className='Title3'>Tax number:</label>
                                        <input
                                            className='inputBarsSmall'
                                            type="text"
                                            id="nif"
                                            value={nif}
                                            onChange={handleTaxChange}
                                        />
                                    </div>
                                </div>

                                {/*people + language*/}
                                <div className='formElementDivGrouped'>
                                    {/*people*/}
                                    <div className='formElementDivGroupedvertical'>
                                        {numPeople === '0' && <label className="error">*</label>}
                                        <label htmlFor="numPeople" className='Title3'>Number of Participants:</label>
                                        <div>
                                            <select className='select' value={numPeople} onChange={handleNumPeopleChange}>
                                            {Object.keys(numPeoplePT).map(key => (
                                                <option className='option' key={key} value={key}>{numPeoplePT[key]}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*language*/}
                                    <div className='formElementDivGroupedvertical'>
                                        {langPref === '0' && <label className="error">*</label>}
                                        <label htmlFor="preflanguage" className='Title3'>Preferred Language:</label>
                                        <div>
                                            <select className='select' value={langPref} onChange={handlePrefLangChange}>
                                            {Object.keys(prefLanguagePT).map(key => (
                                                <option className='option' key={key} value={key}>{prefLanguagePT[key]}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                        </div>                
                    ) : (<div className='formDiv2-right'>
                            <p className='textAvisos'>Select a date to continue...</p>
                        </div>)}
                    </div>

                    <div className='lastDiv'>
                        <button className={`continueButton ${isValidForm ? 'continueButton-selected' : ''}`} type="submit" disabled={!isValidForm}>CONTINUE</button>
                    </div>  
                </form>

                <div className='breakDiv'></div>
                
                {/* Mobile code */}
                <div className='formDiv1-mobile'>
                    <div className='formDiv1-mobile-top'>
                            <p>Challenge</p>
                    </div>
                    <div className='formDiv1-mobile-bottom'>
                        <div>
                            <button className={`formCardChallenges ${desafio === '1' ? 'formCardChallenges-selected' : ''}`} onClick={() => changeChallengeOnClick('1')}>The Theft of the Mask</button>
                        </div>
                    </div>                    
                </div>

                <div className='formDiv2-mobile'>
                    <div className='formDiv2-mobile-top'>
                            <p>Date and Time</p>
                        </div>

                        {/*hide the section while no challenge is selected*/}
                        <div className='formDiv2-mobile-bottom'>
                            {/*Date selection:*/}
                            <div className='formDiv2-mobile-right1'>
                                <Datetime input={false} timeFormat={false} value={selectedDateDateType} onChange={handleDateChange} isValidDate={isValidDate}
                                locale="en"/>
                            </div>

                            {/* Time selection: */}
                            <div className='formDiv2-mobile-right2'>
                            {selectedDate && (
                            <div>
                                {schedule.Schedules.map(scheduleItem => {
                                    if (moment(scheduleItem.Date).isSame(selectedDate, 'day')) {
                                        return (
                                            <div key={scheduleItem.Date}>
                                                {scheduleItem.Hours.map((hour, index) => (
                                                    <button className={selectedHour === hour ? 'cardHour-selected' : 'cardHour'} key={`${scheduleItem.Date}-${index}`} onClick={() => handleButtonHoursClick(hour)}>
                                                        {hour}
                                                    </button>
                                                ))}
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        )}
                            </div>                        
                        </div>
                </div>

                <form onSubmit={validateButton}>
                    <div className='formDiv3-mobile'>
                        <div className='formDiv3-mobile-top'>
                            <p>Client Information</p>
                            <p className='miniLetters'>(The provided data is necessary only for further contact, in case of unforeseen events)</p>
                            <p className="error">* Required field</p>
                        </div>

                        {selectedDate !== null && selectedHour !== '' ? (
                        <div className='formDiv3-mobile-bottom'>
                                {/*name*/}
                                <div className='formElementDiv-mobile'>
                                    <input
                                        className='inputBars-mobile'
                                        placeholder='Name'
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={handleNameChange}
                                        required
                                    />
                                    {!isValidName && <p className="error">*</p>}
                                </div>

                                {/*email*/}
                                <div className='formElementDiv-mobile'>
                                    <input
                                        className='inputBars-mobile'
                                        placeholder='Email'
                                        type="text"
                                        id="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                    {!isValidEmail && <p className="error">*</p>}
                                </div>

                                {/*phone + tax*/}
                                <div className='formElementDiv-mobile'>
                                    {/*phone*/}
                                    <div>
                                        <input
                                            className='inputBars-mobile'
                                            placeholder='Contact'
                                            type="text"
                                            id="phone"
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            required
                                        />
                                        {!isValidPhone && <p className="error">*</p>}
                                    </div>
                                </div>
                                <div className='formElementDiv-mobile'>
                                    {/*tax*/}
                                    <div>
                                        <input
                                            className='inputBars-mobile'
                                            placeholder='Tax Number'
                                            type="text"
                                            id="nif"
                                            value={nif}
                                            onChange={handleTaxChange}
                                        />
                                    </div>
                                </div>

                                {/*people + language*/}
                                <div className='formElementDivGrouped-mobile'>
                                    {/*people*/}
                                    <div className='formElementDivGroupedvertical-mobile'>
                                        {numPeople === '0' && <label className="error">*</label>}
                                        <label htmlFor="numPeople" className='Title3'>Number of Participants:</label>
                                        <div>
                                            <select className='select' value={numPeople} onChange={handleNumPeopleChange}>
                                            {Object.keys(numPeoplePT).map(key => (
                                                <option className='option' key={key} value={key}>{numPeoplePT[key]}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/*language*/}
                                    <div className='formElementDivGroupedvertical-mobile'>
                                    {langPref === '0' && <label className="error">*</label>}
                                        <label htmlFor="preflanguage" className='Title3'>Preferred Language:</label>
                                        <div>
                                            <select className='select' value={langPref} onChange={handlePrefLangChange}>
                                            {Object.keys(prefLanguagePT).map(key => (
                                                <option className='option' key={key} value={key}>{prefLanguagePT[key]}</option>
                                            ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                        </div>                
                    ) : (<div className='formDiv2-right'>
                            <p className='textAvisos'>Select a date to continue...</p>
                        </div>)}
                    </div>

                    <div className='lastDiv-mobile'>
                        <button className={`continueButton-mobile ${isValidForm ? 'continueButton-mobile-selected' : ''}`} type="submit" disabled={!isValidForm}>CONTINUE</button>
                    </div>
                </form>
                
            </div>))}
            
            <div className="divFooter">
                <p className="textFooter">Escape Room Bragança © {currentYear}</p>
            </div> 
        </div>
    );
};

export default Reservas;